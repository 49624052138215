/* Font Face */

@font-face {
	font-family: 'Satisfy Regular';
	src: url('../fonts/Satisfy-Regular.ttf');
}

@font-face {
	font-family: 'Roboto Slab';
	src: url('../fonts/RobotoSerif-VariableFont_GRAD,opsz,wdth,wght.ttf');
}

@font-face {
	font-family: 'LemonSerif Regular';
	src: url('../fonts/LemonSerif-Regular.ttf');
}

/* Variables */

:root {
	--primary-color: #336a86;
	--primary-hover: #2a5670;
	--primary-active: #1e3a54;
	--secondary-color: #f1993d;
	--secondary-hover: #f57c00;
	--secondary-active: #e65100;
	--tertiary-color: #c1c1c1;
	--tertiary-hover: #90a4ae;
	--tertiary-active: #78909c;
	--background-color: #ebefef;
	--background-hover: #d4e9e9;
	--background-active: #b9dfdf;
	--black: #000000;
	--grey: #878787;
	--white: #ffffff;
	--invalid-color: #ff0000;
	// Button
	--button-background: var(--primary-color);
	--button-background-focus: #2a5670;
	--button-background-hover: #2a5670;
	--button-background-active: #1e3a54;
	--button-background-disabled: #878787;
	--button-color: #eef3f3;
	--button-color-focus: #d4e9e9;
	--button-color-hover: #d4e9e9;
	--button-color-active: #b9dfdf;
	--button-color-disabled: #878787;
	// Spinner
	--spinner-color: var(--secondary-color);
	// Input
	--input-background: var(--input-background);
	--input-background-focus: transparent;
	--input-background-hover: transparent;
	--input-background-active: transparent;
	--input-color: var(--tertiary-color);
	--input-color-focus: var(--primary-color);
	--input-color-hover: var(--input-color);
	--input-color-active: var(--input-color);
	--input-label-color: var(--input-color);
	--input-label-color-hover: var(--secondary-color);
	--input-border-color: var(--input-color);
	--input-error-color: var(--invalid-color);
	// Selection
	--selection-background: var(--secondary-color);
	--selection-color: var(--primary-color);
}

[data-theme='dark'] {
	--primary-color: #336a86;
	--primary-hover: #2a5670;
	--primary-active: #1e3a54;
	--primary-active: #1e3a54;
	--secondary-color: #f1993d;
	--secondary-hover: #f57c00;
	--secondary-active: #e65100;
	--tertiary-color: #b0bec5;
	--tertiary-hover: #90a4ae;
	--tertiary-active: #78909c;
	--background-color: #333333;
	--background-hover: #555555;
	--background-active: #777777;
	--black: #ffffff;
	--grey: #878787;
	--white: #000000;
	--invalid-color: #ff0000;
}

/* Colors */

$primary-color: var(--primary-color);
$primary-hover: var(--primary-hover);
$primary-active: var(--primary-active);
$secondary-color: var(--secondary-color);
$secondary-hover: var(--secondary-hover);
$secondary-active: var(--secondary-active);
$tertiary-color: var(--tertiary-color);
$tertiary-hover: var(--tertiary-hover);
$tertiary-active: var(--tertiary-active);
$background-color: var(--background-color);
$background-hover: var(--background-hover);
$background-active: var(--background-active);
$black: var(--black);
$grey: var(--grey);
$white: var(--white);
$invalid-color: var(--invalid-color);
// Button
$button-background: var(--button-background);
$button-background-focus: var(--button-background-focus);
$button-background-hover: var(--button-background-hover);
$button-background-active: var(--button-background-active);
$button-background-disabled: var(--button-background-disabled);
$button-color: var(--button-color);
$button-color-focus: var(--button-color-focus);
$button-color-hover: var(--button-color-hover);
$button-color-active: var(--button-color-active);
$button-color-disabled: var(--button-color-disabled);
// Spinner
$spinner-color: var(--spinner-color);
// Input
$input-background: var(--input-background);
$input-background-focus: var(--input-background-focus);
$input-background-hover: var(--input-background-hover);
$input-background-active: var(--input-background-active);
$input-color: var(--input-color);
$input-color-focus: var(--input-color-focus);
$input-color-hover: var(--input-color-hover);
$input-color-active: var(--input-color-active);
$input-label-color: var(--input-label-color);
$input-label-color-hover: var(--input-label-color-hover);
$input-border-color: var(--input-border-color);
$input-error-color: var(--input-error-color);
// Selection
$selection-background: var(--selection-background);
$selection-color: var(--selection-color);
