@import './variables.scss';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//
	display: flex;
	flex-direction: column;
	padding: 0;
	width: 100dvw;
	height: 100dvh;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: $background-color;
}

#root {
	flex: 1;
	display: flex;
	flex-direction: column;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

// Global styles

*::selection {
	color: $selection-color;
	background-color: $selection-background;
}
